
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getFacilityUnitByFacility } from "@/core/services/api/facilityUnits";
import localstorage from "@/core/services/LocalstorageService";
import { useRouter, useRoute } from "vue-router";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddSeat from "./components/AddSeat.vue";
import UpdateSeat from "./components/UpdateSeat.vue";
import { getFacility } from "@/core/services/api/facilities";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    Datatable,
    AddSeat,
    UpdateSeat,
  },
  emits: ["addFacilityUnit", "updateFacilityUnit"],
  setup() {
    let addSeatModal;
    let updateSeatModal;
    const currentFacilityUnit: any = reactive({
      name: "",
      description: "",
    });
    const facilityMaxIndex = ref(0);
    const facilityData: any = reactive({});
    const isLoadind = ref(false);
    const router = useRouter();
    const route = useRoute();
    const facilityGuid: any = route.params.deviceGuid;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "index",
        name: "排序",
        sortable: true,
      },
      {
        key: "name",
        name: "名稱",
        sortable: true,
      },
      {
        key: "description",
        name: "描述",
        sortable: true,
      },
      {
        key: "operate",
        name: "操作",
        sortable: false,
      },
    ]);

    const setTableData = async () => {
      isLoadind.value = true;
      let facilityUnits = await getFacilityUnitByFacility(
        currentSpaceGuid!,
        facilityGuid
      );
      tableData.splice(0);
      facilityUnits.forEach((o) => {
        tableData.push({
          facilityUnits: o,
          index: o.index,
          name: o.name,
          description: o.description,
        });
      });

      // 根據index排序
      tableData.sort(function (a, b) {
        return a.index - b.index;
      });

      console.log("facilityUnits", facilityUnits);

      isLoadind.value = false;
    };

    const setFacilityData = async () => {
      let response: any = await getFacility(facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const setFacilityMaxIndex = (facility) => {
      let facilityIndexs = facility.map((o) => o.index);
      if (facilityIndexs.length == 0) {
        facilityMaxIndex.value = 0;
      } else {
        facilityMaxIndex.value = Math.max(...facilityIndexs);
      }
    };

    const onAddFacilityUnit = async () => {
      await setTableData();
      addSeatModal.hide();
    };

    const onUpdateFacilityUnit = async () => {
      await setTableData();
      updateSeatModal.hide();
    };

    const setCurrentFacilityUnit = (item) => {
      Object.assign(currentFacilityUnit, item);
    };

    onMounted(() => {
      addSeatModal = new Modal(
        document.getElementById("deviceManagement-addSeat-modal")
      );
      updateSeatModal = new Modal(
        document.getElementById("deviceManagement-updateSeat-modal")
      );
    });

    const init = async () => {
      await setFacilityData();
      await setTableData();
      setFacilityMaxIndex(tableData);
    };
    init();

    return {
      tableData,
      tableHeader,
      facilityData,
      facilityMaxIndex,
      facilityGuid,
      setTableData,
      setCurrentFacilityUnit,
      currentFacilityUnit,
      onAddFacilityUnit,
      onUpdateFacilityUnit,
    };
  },
});
