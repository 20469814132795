import ApiService from "@/core/services/ApiService";
import { FacilityUnit } from "@/core/services/responsesModel/facilityUnit"

// 取得所有FacilityUnit資料
export function getFacilityUnitByFacility(spaceGuid: string,facilityGuid:string): Promise<Array<FacilityUnit>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Spaces/${spaceGuid}/Facility/${facilityGuid}/FacilityUnit`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 根據FacilityGuid陣列取得所有FacilityUnit資料
export function getFacilityUnitByFacilities(request): Promise<Array<FacilityUnit>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`FacilityUnits/QueryByFacilities`,request).then(res => {
            resolve(res.data.facilityUnits)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增FacilityUnit
export function addFacilityUnit(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`FacilityUnits/Add`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 更新FacilityUnit
export function updateFacilityUnit(request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`FacilityUnits/UpdateData`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}