<template>
  <el-form
    @submit.prevent="submit()"
    :model="form"
    :rules="rules"
    ref="formRef"
    class="form"
  >
    <div>
      <label class="fs-6 fw-bold mb-2">
        <span class="required">名稱</span>
      </label>
      <el-form-item prop="name">
        <el-input v-model="form.name" placeholder="請輸入名稱"></el-input>
      </el-form-item>
      <label class="fs-6 fw-bold mb-2">
        <span>描述</span>
      </label>
      <el-form-item>
        <el-input
          type="textarea"
          placeholder="輸入描述"
          v-model="form.description"
        ></el-input>
      </el-form-item>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { updateFacilityUnit } from "@/core/services/api/facilityUnits";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  props: ["facilityUnitData", "facilityGuid"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
    });
    const form = reactive({
      name: "",
      description: "",
    });

    const reseatForm = () => {
      Object.assign(form, {
        name: "",
        description: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要修改嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("form", form);
              let request = {
                guid: props.facilityUnitData.facilityUnits.guid,
                name: form.name,
                description: form.description,
              };
              await updateFacilityUnit(request);
              await Swal.fire("更新成功!", "", "success");
              emit("updateFacilityUnit");
              reseatForm();
            }
          });
        }
      });
    };

    const setFormData = () => {
      Object.assign(form, {
        name: props.facilityUnitData.name,
        description: props.facilityUnitData.description,
      });
    };

    watch(
      props,
      () => {
        setFormData();
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      let addSeatModal: any = document.getElementById(
        "deviceManagement-updateSeat-modal"
      );
      addSeatModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
    };
  },
});
</script>

<style>
</style>