<template>
  <div class="card">
    <div
      class="
        card-header
        border-0
        pt-6
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h1>{{ facilityData.name }}</h1>
      <button
        type="button"
        class="btn btn-success"
        data-bs-toggle="modal"
        data-bs-target="#deviceManagement-addSeat-modal"
      >
        新增
      </button>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-index="{ row: tableData }">
          {{ tableData.index }}
        </template>
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-description="{ row: tableData }">
          {{ tableData.description }}
        </template>

        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentFacilityUnit(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#deviceManagement-updateSeat-modal"
                  >修改</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 新增座位modal -->
  <div
    class="modal fade"
    id="deviceManagement-addSeat-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="deviceManagement-addSeat-exampleModalLabel"
          >
            新增座位
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddSeat
            :facilityGuid="facilityGuid"
            :facilityMaxIndex="facilityMaxIndex"
            @addFacilityUnit="onAddFacilityUnit()"
          ></AddSeat>
        </div>
      </div>
    </div>
  </div>
  <!-- 修改座位資料modal -->
  <div
    class="modal fade"
    id="deviceManagement-updateSeat-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="deviceManagement-updateSeat-exampleModalLabel"
          >
            修改座位資料
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <UpdateSeat
            :facilityGuid="facilityGuid"
            :facilityUnitData="currentFacilityUnit"
            @updateFacilityUnit="onUpdateFacilityUnit()"
          ></UpdateSeat>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getFacilityUnitByFacility } from "@/core/services/api/facilityUnits";
import localstorage from "@/core/services/LocalstorageService";
import { useRouter, useRoute } from "vue-router";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddSeat from "./components/AddSeat.vue";
import UpdateSeat from "./components/UpdateSeat.vue";
import { getFacility } from "@/core/services/api/facilities";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    Datatable,
    AddSeat,
    UpdateSeat,
  },
  emits: ["addFacilityUnit", "updateFacilityUnit"],
  setup() {
    let addSeatModal;
    let updateSeatModal;
    const currentFacilityUnit: any = reactive({
      name: "",
      description: "",
    });
    const facilityMaxIndex = ref(0);
    const facilityData: any = reactive({});
    const isLoadind = ref(false);
    const router = useRouter();
    const route = useRoute();
    const facilityGuid: any = route.params.deviceGuid;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        key: "index",
        name: "排序",
        sortable: true,
      },
      {
        key: "name",
        name: "名稱",
        sortable: true,
      },
      {
        key: "description",
        name: "描述",
        sortable: true,
      },
      {
        key: "operate",
        name: "操作",
        sortable: false,
      },
    ]);

    const setTableData = async () => {
      isLoadind.value = true;
      let facilityUnits = await getFacilityUnitByFacility(
        currentSpaceGuid!,
        facilityGuid
      );
      tableData.splice(0);
      facilityUnits.forEach((o) => {
        tableData.push({
          facilityUnits: o,
          index: o.index,
          name: o.name,
          description: o.description,
        });
      });

      // 根據index排序
      tableData.sort(function (a, b) {
        return a.index - b.index;
      });

      console.log("facilityUnits", facilityUnits);

      isLoadind.value = false;
    };

    const setFacilityData = async () => {
      let response: any = await getFacility(facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const setFacilityMaxIndex = (facility) => {
      let facilityIndexs = facility.map((o) => o.index);
      if (facilityIndexs.length == 0) {
        facilityMaxIndex.value = 0;
      } else {
        facilityMaxIndex.value = Math.max(...facilityIndexs);
      }
    };

    const onAddFacilityUnit = async () => {
      await setTableData();
      addSeatModal.hide();
    };

    const onUpdateFacilityUnit = async () => {
      await setTableData();
      updateSeatModal.hide();
    };

    const setCurrentFacilityUnit = (item) => {
      Object.assign(currentFacilityUnit, item);
    };

    onMounted(() => {
      addSeatModal = new Modal(
        document.getElementById("deviceManagement-addSeat-modal")
      );
      updateSeatModal = new Modal(
        document.getElementById("deviceManagement-updateSeat-modal")
      );
    });

    const init = async () => {
      await setFacilityData();
      await setTableData();
      setFacilityMaxIndex(tableData);
    };
    init();

    return {
      tableData,
      tableHeader,
      facilityData,
      facilityMaxIndex,
      facilityGuid,
      setTableData,
      setCurrentFacilityUnit,
      currentFacilityUnit,
      onAddFacilityUnit,
      onUpdateFacilityUnit,
    };
  },
});
</script>

<style>
</style>