
import { defineComponent, onMounted, reactive, ref } from "vue";
import { addFacilityUnit } from "@/core/services/api/facilityUnits";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  props: ["facilityGuid", "facilityMaxIndex"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      name: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
    });
    const form = reactive({
      name: "",
      description: "",
    });

    const reseatForm = () => {
      Object.assign(form, {
        name: "",
        description: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                facility: props.facilityGuid,
                name: form.name,
                description: form.description,
                index: props.facilityMaxIndex + 1,
                status: "Normal",
              };
              await addFacilityUnit(request);
              await Swal.fire("新增成功!", "", "success");
              emit("addFacilityUnit");
              reseatForm();
            }
          });
        }
      });
    };

    onMounted(() => {
      let addSeatModal: any = document.getElementById(
        "deviceManagement-addSeat-modal"
      );
      addSeatModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
      });
    });

    return {
      rules,
      formRef,
      form,
      submit,
    };
  },
});
